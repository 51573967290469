import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Api from '../Lib/Api';
import OrderList from '../Components/OrderList';
import { Modal } from 'react-bootstrap';
import OrderEditForm from '../Components/Forms/OrderEditForm';

const Component = (props) => {
  const [isEditModalOpen, openEditModal] = useState(false);
  const [order, setOrder] = useState(null);

  const onEditOrderClicked = (o) => {
    setOrder(o);
    openEditModal(true);
  }

  const onMarkAsClicked = (item, received, order_note) => {
    if (item.quantity_requested < received) {
      toast.error('Quantity received can not be more than quantity requested. Fill out another order if you are taking more.')
      return;
    }

    props.editOrderItem({
      id: item.order_item_id,
      quantity_received: received,
      note: order_note.length > 0 ? order_note : null,
      completed_at: new Date(),
    }).then(() => {
      props.getOrders();
      props.getItems();
    });
  }

  const closeEditModal = () => {
    openEditModal(false)
    setOrder(null);
  }

  return (
    <main>
      <section className="page-heading-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
                <h1 className="page-title">History</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section>
        {props.orders.length < 1 && (
          <div className="simple-container subtle">
            There are no requests to show.
          </div>
        )}
        {props.orders.map(o => (
          <OrderList
            key={o.id}
            order={o}
            onEditOrderClicked={onEditOrderClicked}
            onMarkAsClicked={onMarkAsClicked}
          />
        ))}

        {/* <Modal
          show={isEditModalOpen}
          onHide={closeEditModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Edit Order Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <OrderEditForm
              order={order}
              onSubmit={closeEditModal}
              onCancel={closeEditModal}
            />
          </Modal.Body>
        </Modal> */}
      </section>
    </main>
  )
}

const mapStateToProps = state => ({
  orders: state.orders,
});

const mapDispatchToProps = {
  getOrders: Api.getOrders,
  getItems: Api.getItems,
  editOrderItem: Api.editOrderItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
