import Auth from './Auth';

import Action from './Action';

const Api = {
  _call: (action, method, url, data = {}, params = {}) => ({
    type: action,
    payload: {
      request: {
        headers: {
          Authorization: Auth.getBearerToken()
        },
        method,
        url,
        data,
        params,
      },
    },
  }),


  _get: (action, url, params) => Api._call(action, 'get', url, {}, params),
  _post: (action, url, data, params) => Api._call(action, 'post', url, data, params),
  _put: (action, url, data, params) => Api._call(action, 'put', url, data, params),
  _delete: (action, url, params) => Api._call(action, 'delete', url, {}, params),

  // AUTH
  authenticate: data => Api._post(Action.AUTHENTICATE, '/auth/login', data),
  forgotPassword: data => Api._post(Action.FORGOT_PASSWORD, '/auth/forgot-password', data),
  refreshToken: data => Api._post(Action.REFRESH_TOKEN, '/auth/refresh', data),

  getOrganizations: params => Api._get(Action.GET_ORGANIZATIONS, '/organizations', params),
  createOrganization: data => Api._post(Action.CREATE_ORGANIZATION, '/organizations', data),
  editOrganization: data => Api._put(Action.EDIT_ORGANIZATION, `/organizations/${data.id}`, data),
  deleteOrganization: id => Api._delete(Action.DELETE_ORGANIZATION, `/organizations/${id}`),

  getItems: params => Api._get(Action.GET_ITEMS, '/items', params),
  createItem: data => Api._post(Action.CREATE_ITEM, '/items', data),
  editItem: data => Api._put(Action.EDIT_ITEM, `/items/${data.id}`, data),
  copyItem: data => Api._post(Action.COPY_ITEM, `/items/${data.id}/copy`, data),
  deleteItem: data => Api._delete(Action.DELETE_ITEM, `/items/${data.id}`),

  getUsers: params => Api._get(Action.GET_USERS, '/users', params),
  createUser: data => Api._post(Action.CREATE_USER, '/users', data),
  editUser: data => Api._put(Action.EDIT_USER, `/users/${data.id}`, data),
  deleteUser: id => Api._delete(Action.DELETE_USER, `/users/${id}`),

  getOrders: params => Api._get(Action.GET_ORDERS, '/orders', params),
  createOrder: data => Api._post(Action.CREATE_ORDER, '/orders', data),
  editOrder: data => Api._put(Action.EDIT_ORDER, `/orders/${data.id}`, data),

  editOrderItem: data => Api._put(Action.EDIT_ORDER_ITEM, `/order-items/${data.id}`, data),

  getSuppliers: params => Api._get(Action.GET_SUPPLIERS, '/suppliers', params),
  createSupplier: data => Api._post(Action.CREATE_SUPPLIER, '/suppliers', data),

  getLocations: params => Api._get(Action.GET_LOCATIONS, '/locations', params),
  editLocation: data => Api._put(Action.EDIT_LOCATION, `/locations/${data.id}`, data),
  createLocation: data => Api._post(Action.CREATE_LOCATION, '/locations', data),
  deleteLocation: id => Api._delete(Action.DELETE_LOCATION, `/locations/${id}`),

  createItemChange: data => Api._post(Action.CREATE_ITEM_CHANGE, '/item-changes', data),
  getItemChanges: params => Api._get(Action.GET_ITEM_CHANGES, '/item-changes', params),
  editItemChange: data => Api._put(Action.EDIT_ITEM_CHANGE, `/item-changes/${data.id}`, data),
  deleteItemChange: id => Api._delete(Action.DELETE_ITEM_CHANGE, `/item-changes/${id}`),

  sendCustomMessage: data => Api._post(Action.SEND_CUSTOM_MESSAGE, '/admin/messaging', data),
  sendDistributionEmail: data => Api._post(Action.SEND_DISTRIBUTION_EMAIL, '/distribution/report', data),
  getSettings: params => Api._get(Action.GET_SETTINGS, '/admin/settings', params),
  setSettings: data => Api._put(Action.SET_SETTINGS, '/admin/settings', data),
};

export default Api;
