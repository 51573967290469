export default {
  // AUTH
  AUTHENTICATE: 'AUTHENTICATE',
  AUTHENTICATE_SUCCESS: 'AUTHENTICATE_SUCCESS',
  AUTHENTICATE_FAIL: 'AUTHENTICATE_FAIL',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAIL: 'FORGOT_PASSWORD_FAIL',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAIL: 'REFRESH_TOKEN_FAIL',
  
  // ITEMS
  GET_ITEMS: 'GET_ITEMS',
  GET_ITEMS_SUCCESS: 'GET_ITEMS_SUCCESS',
  GET_ITEMS_FAIL: 'GET_ITEMS_FAIL',
  CREATE_ITEM: 'CREATE_ITEM',
  CREATE_ITEM_SUCCESS: 'CREATE_ITEM_SUCCESS',
  CREATE_ITEM_FAIL: 'CREATE_ITEM_FAIL',
  GET_ITEM: 'GET_ITEM',
  GET_ITEM_SUCCESS: 'GET_ITEM_SUCCESS',
  GET_ITEM_FAIL: 'GET_ITEM_FAIL',
  EDIT_ITEM: 'EDIT_ITEM',
  EDIT_ITEM_SUCCESS: 'EDIT_ITEM_SUCCESS',
  EDIT_ITEM_FAIL: 'EDIT_ITEM_FAIL',
  DELETE_ITEM: 'DELETE_ITEM',
  DELETE_ITEM_SUCCESS: 'DELETE_ITEM_SUCCESS',
  DELETE_ITEM_FAIL: 'DELETE_ITEM_FAIL',
  COPY_ITEM: 'COPY_ITEM',
  COPY_ITEM_SUCCESS: 'COPY_ITEM_SUCCESS',
  COPY_ITEM_FAIL: 'COPY_ITEM_FAIL',
  
  // ORGANIZATIONS
  GET_ORGANIZATIONS: 'GET_ORGANIZATIONS',
  GET_ORGANIZATIONS_SUCCESS: 'GET_ORGANIZATIONS_SUCCESS',
  GET_ORGANIZATIONS_FAIL: 'GET_ORGANIZATIONS_FAIL',
  CREATE_ORGANIZATION: 'CREATE_ORGANIZATION',
  CREATE_ORGANIZATION_SUCCESS: 'CREATE_ORGANIZATION_SUCCESS',
  CREATE_ORGANIZATION_FAIL: 'CREATE_ORGANIZATION_FAIL',
  GET_ORGANIZATION: 'GET_ORGANIZATION',
  GET_ORGANIZATION_SUCCESS: 'GET_ORGANIZATION_SUCCESS',
  GET_ORGANIZATION_FAIL: 'GET_ORGANIZATION_FAIL',
  EDIT_ORGANIZATION: 'EDIT_ORGANIZATION',
  EDIT_ORGANIZATION_SUCCESS: 'EDIT_ORGANIZATION_SUCCESS',
  EDIT_ORGANIZATION_FAIL: 'EDIT_ORGANIZATION_FAIL',
  DELETE_ORGANIZATION: 'DELETE_ORGANIZATION',
  DELETE_ORGANIZATION_SUCCESS: 'DELETE_ORGANIZATION_SUCCESS',
  DELETE_ORGANIZATION_FAIL: 'DELETE_ORGANIZATION_FAIL',
  
  // USERS
  GET_USERS: 'GET_USERS',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAIL: 'GET_USERS_FAIL',
  CREATE_USER: 'CREATE_USER',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAIL: 'CREATE_USER_FAIL',
  GET_USER: 'GET_USER',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAIL: 'GET_USER_FAIL',
  EDIT_USER: 'EDIT_USER',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  EDIT_USER_FAIL: 'EDIT_USER_FAIL',
  DELETE_USER: 'DELETE_USER',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAIL: 'DELETE_USER_FAIL',
  
  // ORDERS
  GET_ORDERS: 'GET_ORDERS',
  GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
  GET_ORDERS_FAIL: 'GET_ORDERS_FAIL',
  CREATE_ORDER: 'CREATE_ORDER',
  CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAIL: 'CREATE_ORDER_FAIL',
  GET_ORDER: 'GET_ORDER',
  GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
  GET_ORDER_FAIL: 'GET_ORDER_FAIL',
  EDIT_ORDER: 'EDIT_ORDER',
  EDIT_ORDER_SUCCESS: 'EDIT_ORDER_SUCCESS',
  EDIT_ORDER_FAIL: 'EDIT_ORDER_FAIL',
  EDIT_ORDER_ITEM: 'EDIT_ORDER_ITEM',
  EDIT_ORDER_ITEM_SUCCESS: 'EDIT_ORDER_ITEM_SUCCESS',
  EDIT_ORDER_ITEM_FAIL: 'EDIT_ORDER_ITEM_FAIL',
  
  // SUPPLIERS
  GET_SUPPLIERS: 'GET_SUPPLIERS',
  GET_SUPPLIERS_SUCCESS: 'GET_SUPPLIERS_SUCCESS',
  GET_SUPPLIERS_FAIL: 'GET_SUPPLIERS_FAIL',
  CREATE_SUPPLIER: 'CREATE_SUPPLIER',
  CREATE_SUPPLIER_SUCCESS: 'CREATE_SUPPLIER_SUCCESS',
  CREATE_SUPPLIER_FAIL: 'CREATE_SUPPLIER_FAIL',
  GET_SUPPLIER: 'GET_SUPPLIER',
  GET_SUPPLIER_SUCCESS: 'GET_SUPPLIER_SUCCESS',
  GET_SUPPLIER_FAIL: 'GET_SUPPLIER_FAIL',
  EDIT_SUPPLIER: 'EDIT_SUPPLIER',
  EDIT_SUPPLIER_SUCCESS: 'EDIT_SUPPLIER_SUCCESS',
  EDIT_SUPPLIER_FAIL: 'EDIT_SUPPLIER_FAIL',
  
  // LOCATIONS
  GET_LOCATIONS: 'GET_LOCATIONS',
  GET_LOCATIONS_SUCCESS: 'GET_LOCATIONS_SUCCESS',
  GET_LOCATIONS_FAIL: 'GET_LOCATIONS_FAIL',
  CREATE_LOCATION: 'CREATE_LOCATION',
  CREATE_LOCATION_SUCCESS: 'CREATE_LOCATION_SUCCESS',
  CREATE_LOCATION_FAIL: 'CREATE_LOCATION_FAIL',
  GET_LOCATION: 'GET_LOCATION',
  GET_LOCATION_SUCCESS: 'GET_LOCATION_SUCCESS',
  GET_LOCATION_FAIL: 'GET_LOCATION_FAIL',
  EDIT_LOCATION: 'EDIT_LOCATION',
  EDIT_LOCATION_SUCCESS: 'EDIT_LOCATION_SUCCESS',
  EDIT_LOCATION_FAIL: 'EDIT_LOCATION_FAIL',
  DELETE_LOCATION: 'DELETE_LOCATION',
  DELETE_LOCATION_SUCCESS: 'DELETE_LOCATION_SUCCESS',
  DELETE_LOCATION_FAIL: 'DELETE_LOCATION_FAIL',
  
  // ITEM CHANGES
  GET_ITEM_CHANGES: 'GET_ITEM_CHANGES',
  GET_ITEM_CHANGES_SUCCESS: 'GET_ITEM_CHANGES_SUCCESS',
  GET_ITEM_CHANGES_FAIL: 'GET_ITEM_CHANGES_FAIL',
  CREATE_ITEM_CHANGE: 'CREATE_ITEM_CHANGE',
  CREATE_ITEM_CHANGE_SUCCESS: 'CREATE_ITEM_CHANGE_SUCCESS',
  CREATE_ITEM_CHANGE_FAIL: 'CREATE_ITEM_CHANGE_FAIL',
  GET_ITEM_CHANGE: 'GET_ITEM_CHANGE',
  GET_ITEM_CHANGE_SUCCESS: 'GET_ITEM_CHANGE_SUCCESS',
  GET_ITEM_CHANGE_FAIL: 'GET_ITEM_CHANGE_FAIL',
  EDIT_ITEM_CHANGE: 'EDIT_ITEM_CHANGE',
  EDIT_ITEM_CHANGE_SUCCESS: 'EDIT_ITEM_CHANGE_SUCCESS',
  EDIT_ITEM_CHANGE_FAIL: 'EDIT_ITEM_CHANGE_FAIL',
  DELETE_ITEM_CHANGE: 'DELETE_ITEM_CHANGE',
  DELETE_ITEM_CHANGE_SUCCESS: 'DELETE_ITEM_CHANGE_SUCCESS',
  DELETE_ITEM_CHANGE_FAIL: 'DELETE_ITEM_CHANGE_FAIL',
  
  // ADMIN
  SEND_CUSTOM_MESSAGE: 'SEND_CUSTOM_MESSAGE',
  SEND_CUSTOM_MESSAGE_SUCCESS: 'SEND_CUSTOM_MESSAGE_SUCCESS',
  SEND_CUSTOM_MESSAGE_FAIL: 'SEND_CUSTOM_MESSAGE_FAIL',
  GET_SETTINGS: 'GET_SETTINGS',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAIL: 'GET_SETTINGS_FAIL',
  SET_SETTINGS: 'SET_SETTINGS',
  SET_SETTINGS_SUCCESS: 'SET_SETTINGS_SUCCESS',
  SET_SETTINGS_FAIL: 'SET_SETTINGS_FAIL',
  
  SEND_DISTRIBUTION_EMAIL: 'SEND_DISTRIBUTION_EMAIL',
  SEND_DISTRIBUTION_EMAIL_SUCCESS: 'SEND_DISTRIBUTION_EMAIL_SUCCESS',
  SEND_DISTRIBUTION_EMAIL_FAIL: 'SEND_DISTRIBUTION_EMAIL_FAIL',
}