import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Api from '../Lib/Api';
import { Modal } from 'react-bootstrap';
import moment from 'moment-timezone';

const renderChangeTypeLabel = (change) => {
  switch (change?.change_type) {
    case 'claimed_by':
      return `Claim of ${change.item_name}`;
    case 'compost':
      return `Composting of ${change.item_name}`;
    case 'livestock_feed':
      return `Livestock Feed of ${change.item_name}`;
    case 'waste':
      return `Waste of ${change.item_name}`;
    case 'value_add':
      return `Value Add of ${change.item_name}`;
    default:
      return `Unknown Change Type: ${change ? change.change_type : 'Unknown'}`;
  }
}

const Component = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [deleteItemChangeModalActive, setDeleteItemChangeModalActive] = useState(false);
  const [deleteItemChange, setDeleteItemChange] = useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [doubleConfirmDelete, setDoubleConfirmDelete] = useState(false);

  const getData = () => {
    props.getItemChanges().then((action) => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = () => {
    if (!isConfirmingDelete) {
      setIsConfirmingDelete(true);
      return;
    } else {
      props.deleteItemChange(deleteItemChange.id).then((action) => {
        getData();
        onDeleteModalClose();
      });
    }
  }

  const onDeleteModalClose = () => {
    getData();
    setDeleteItemChangeModalActive(false);
    setDeleteItemChange(null);
  }

  const onDeleteClicked = (item) => {
    setDeleteItemChange(item);
    setDeleteItemChangeModalActive(true);
  }

  return (
    <main>
      <section className="page-heading-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
                <h1 className="page-title">Item Changes List</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <table className="table">
                <thead>
                <tr>
                  <th scope="col">Item Change</th>
                  <th scope="col">Quantity (16/32/Fam)</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                {!isLoading && props.itemChanges?.length === 0 && (
                  <tr><td scope="row">No Item Changes Found</td></tr>
                )}
                {isLoading ? (<tr><td scope="row">Loading Report</td></tr>) : props.itemChanges?.map((l) => (
                  <tr key={l.id}>
                    <td scope="row">{renderChangeTypeLabel(l)}</td>
                    <td>{l.quantity || `${l.container_16oz} | ${l.container_32oz} | ${l.container_family}`}</td>
                    <td>{moment.tz(l.created_at, moment.tz.guess()).format('MMM/DD/YYYY h:mm:ss a')}</td>
                    <td>
                      <button type="button" className="btn btn-danger" onClick={() => onDeleteClicked(l)}>Delete</button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Modal
          show={deleteItemChangeModalActive}
          onHide={onDeleteModalClose}
          backdrop="static"
          keyboard={false}
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Item Change Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p>
                {doubleConfirmDelete
                ? 'This action cannot be undone. Are you sure you want to delete this item change?'
                : 'Are you sure you want to delete this item change?'}
              </p>
              <p>{renderChangeTypeLabel(deleteItemChange)}</p>

              <button
                className="btn btn-danger"
                onClick={doubleConfirmDelete
                  ? () => handleDelete()
                  : () => setDoubleConfirmDelete(true)}
              >
                Delete
              </button>
          </Modal.Body>
        </Modal>
      </section>
    </main>
  )
}

const mapStateToProps = state => ({
    itemChanges: state.itemChanges
})
const mapDispatchToProps = {
  getItemChanges: Api.getItemChanges,
  deleteItemChange: Api.deleteItemChange
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
