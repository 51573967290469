import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Api from '../Lib/Api';
import useKeyPress from '../Hooks/useKeyPress';
import Action from '../Lib/Action';
import Auth from '../Lib/Auth';
import AnimatedButton from '../Components/AnimatedButton';

const Component = (props) => {
  const params = new URLSearchParams(useLocation().search);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  if (Auth.isLoggedIn()) { props.history.push('/') }

  const onClick = () => {
    setIsLoading(true);
    if (params.get('code')) {
      props.forgotPassword({ password, code: params.get('code') }).then((action) => {
        setIsLoading(false);
        if (action.type === Action.FORGOT_PASSWORD_SUCCESS) {
          props.history.push('/login');
          toast.success('Password successully changed.')
        } else {
          props.history.replace('/forgot-password');
          toast.error('Error changing password. Please try again.')
        }
      });
    } else {
      props.forgotPassword({ email }).then((action) => {
        setIsLoading(false);
        if (action.type === Action.FORGOT_PASSWORD_SUCCESS) {
          props.history.push('/login');
          toast.success('Forgot password email sent.')
        }
      });
    }
  };

  const onKeyPress = ({ key }) => {
    if (key === "Enter") onClick();
  };

  const code = params.get('code');

  return (
    <main>
      <section>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-md-4">
              <h2 className="mt-50">Forgot Password</h2>
              <form className="authentication-form">
                {code ? (
                  <label className="form-field">
                    <div>New Password</div>
                    <input
                      type="password"
                      autoComplete="new-password"
                      id="auth-password"
                      placeholder="Enter a new password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      onKeyPress={onKeyPress}
                    />
                  </label>
                ) : (
                  <label className="form-field">
                    <div>Email</div>
                    <input
                      type="email"
                      autoComplete="email"
                      id="auth-email"
                      placeholder="you@domain.com"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      onKeyPress={onKeyPress}
                    />
                  </label>
                )}
                <div className="form-field">
                  <div className="link" onClick={() => props.history.push('login')}>Remember your password? Log in!</div>
                </div>
                <div className="form-field">
                  <AnimatedButton isLoading={isLoading} onClick={onClick} className="btn">{code ? 'Change Password' : 'Send Request'}</AnimatedButton>
                </div>
              </form>
              {/* <div className="form-alternative-actions">
                <Link to="#">Forgot Password</Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

const mapStateToProps = state => ({});
const mapDispatchToProps = {
  forgotPassword: Api.forgotPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
