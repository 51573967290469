import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import RequiresAdmin from '../Components/RequiresAdmin';
import Api from '../Lib/Api';
import adminIconDistributionMessage from '../Images/admin-icon-distribution-message.svg';
import adminIconCustomMessage from '../Images/admin-icon-custom-message.svg';
import adminIconReports from '../Images/admin-icon-reports.svg';
import adminIconKioskMode from '../Images/admin-icon-kioskmode.svg';

const Component =  (props) => {
  const [inKioskMode, setInKioskMode] = React.useState(undefined);
  
  const toggleKiosk = () => {
    
    if (inKioskMode) {
      localStorage.setItem('options', '{"kiosk":false}');
      location.reload();
    } else {
      localStorage.setItem('options', '{"kiosk":true}');
      location.reload();
    }
  }

  React.useEffect(() => {
    const options = JSON.parse(localStorage.getItem('options') || '{}');
    setInKioskMode(options.kiosk ?? false);
  }, []);

  return (
    <main>
      <RequiresAdmin reverse><Redirect to="/" /></RequiresAdmin>

      <section className="page-heading-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
                <h1 className="page-title">Admin Panel</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
      <div className="container-fluid">
          <div className="row admin-panel-dashboard">
            <div className="col-12 col-md-3">
              <Link className="admin-card" to="/distribution">
                <img src={adminIconDistributionMessage} alt="Distribution Message" className="icon" />
                <h3>Distribution Message</h3>
              </Link>
            </div>
            <div className="col-12 col-md-3">
              <Link className="admin-card" to="/messaging">
                <img src={adminIconCustomMessage} alt="Custom Message" className="icon" />
                <h3>Custom Messaging</h3>
              </Link>
            </div>
            <div className="col-12 col-md-3">
              <Link className="admin-card" to="/item-changes">
                <img src={adminIconReports} alt="Custom Message" className="icon" />
                <h3>Item Changes</h3>
              </Link>
            </div>
            <div className="col-12 col-md-3">
              <Link className="admin-card" to="/reports">
                <img src={adminIconReports} alt="Reports" className="icon" />
                <h3>Reports</h3>
              </Link>
            </div>
            <div className="col-12 col-md-3">
              <div className="admin-card" onClick={toggleKiosk}>
                <img src={adminIconKioskMode} alt="Kiosk Mode" className="icon" />
                <h3>{inKioskMode ? 'Disable' : 'Enable'} Kiosk Mode</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

const mapStateToProps = state => ({
})
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
