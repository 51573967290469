import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Api from '../../Lib/Api';
import useKeyPress from '../../Hooks/useKeyPress';
import AnimatedButton from '../AnimatedButton';


const Component = (props) => {
  const [note, setNote] = useState(props.order ? props.order.note : '');
  const [isLoading, setIsLoading] = useState(false);

  useKeyPress(27, () => { if (props.onCancel) props.onCancel(); });

  const submit = () => {
    setIsLoading(true);
    props.editOrder({
      id: props.order.id,
      note,
    }).then(() => {
      props.getOrders();
      setIsLoading(false);
      if (props.onSubmit) props.onSubmit();
    });
  }

  return (
    <div>
      <Form className="add-item-form">
        {/* <Form.Group>
          <Form.Label>Note</Form.Label>
          <Form.Control placeholder="Enter a note about the order" value={note} onChange={e => setNote(e.target.value)} />
        </Form.Group> */}

        <div className="btn-group">
          <AnimatedButton isLoading={isLoading} onClick={() => submit()} className="btn">Save</AnimatedButton>
          <div className="btn btn-outline" onClick={props.onCancel}>Cancel</div>
        </div>
      </Form>
    </div>
  );
}

const mapStateToProps = state => ({});
const mapDispatchToProps = {
  getOrders: Api.getOrders,
  editOrder: Api.editOrder,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
